import { __pbUser } from "~/stores/pb-user.store";

import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(async () => {
	if (__pbUser().isLoading || !__pbUser().isInitialized) {
		await waitForPB();
		await new Promise(resolve => setTimeout(resolve, 1000));
	}

	if (__pbUser().isReseller) {
		log("❌ Is reseller");
		return navigateTo("/");
	}

	log("✅ Is not reseller");
});
